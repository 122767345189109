import { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

// Components
import Search from '../components/search';
import ArticleList from '../components/articleList';
import Loading from '../components/loading';

// Atoms
import { postsAtom, postsTotalpagesAtom, searchQueryAtom, searchPostAtom, isLoadingAtom, currentPageAtom } from '../recoil/atoms/postsatom';

// Vars
import { apiUrl, apiSearchUrl } from '../components/vars';

const Archive = () => {
    
    const [isLoading, setIsLoading] = useRecoilState(isLoadingAtom);
    const [posts, setPosts] = useRecoilState(postsAtom);
    const [postsTotalpages, setPostsTotalpages] = useRecoilState(postsTotalpagesAtom);
    
    const [searchPost, setSearchPost] = useRecoilState(searchPostAtom);
    
    const [currentPage, setCurrentPage] = useRecoilState(currentPageAtom);
    
    useEffect(() => {
        
        setIsLoading(true);
        
        setSearchPost([]);
        
        setCurrentPage(1);
        
        fetch(apiUrl)
        .then((response) => {
            setPostsTotalpages(response.headers.get('x-wp-totalpages'));
            return response.json();
        })
        .then((responseData) => {
            setPosts(responseData);
            setIsLoading(false);
        })
        .catch((error)=>{
            //fetch自体が失敗したとき
            console.log("取得に失敗しました。" + "error: " + error);
        })

        
    }, [setPosts])
    
    const [searchQuery, setSearchQuery] = useRecoilState(searchQueryAtom)
    
    const onChangeInput = event => {
        
        setSearchQuery(event.target.value);
        
        if(event.target.value.length >=2){
            setIsLoading(true);
        
            setPosts([]);
            
            fetch(`${apiSearchUrl}${searchQuery}&_embed&per_page=50`)
            .then((response) => {
                setPostsTotalpages(response.headers.get('x-wp-totalpages'));
                return response.json();
            })
            .then((responseData) => {
                setSearchPost(responseData);
                setIsLoading(false);
            })
            .catch((error)=>{
                //fetch自体が失敗したとき
                console.log("取得に失敗しました。" + "error: " + error);
            })
            
        }else{
            
            setIsLoading(true);
        
            setSearchPost([]);
            
            fetch(apiUrl)
            .then((response) => {
                setPostsTotalpages(response.headers.get('x-wp-totalpages'));
                return response.json();
            })
            .then((responseData) => {
                setPosts(responseData);
                setIsLoading(false);
            })
            .catch((error)=>{
                //fetch自体が失敗したとき
                console.log("取得に失敗しました。" + "error: " + error);
            })
            
        }
        
        
    }
    
    return(
        <main className="main">
                    
            <Search onChangeInput={onChangeInput} />
            
            { isLoading ? <Loading /> : <ArticleList posts={ posts } postsTotalpages={ postsTotalpages } searchPost={ searchPost } /> }
            
        </main>
    )
}

export default Archive;
