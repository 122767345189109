import { useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from 'recoil';
import { Helmet } from "react-helmet";

// Components
import ArticleList from '../components/articleList';
import Loading from '../components/loading';

// Atoms
import { postsAtom, postsTotalpagesAtom, searchPostAtom, isLoadingAtom } from '../recoil/atoms/postsatom';

// Vars
import { apiTagsPostsUrl } from '../components/vars';

const TagsArchives = () => {
    
    const { id } = useParams();
    
    const [isLoading, setIsLoading] = useRecoilState(isLoadingAtom);
    const [postsTotalpages, setPostsTotalpages] = useRecoilState(postsTotalpagesAtom);
    const [posts, setPosts] = useRecoilState(postsAtom);
    const [searchPost, setSearchPost] = useRecoilState(searchPostAtom);
    
    useEffect(() => {
        
        setIsLoading(true);
        
        setSearchPost([]);
        
        fetch(`${apiTagsPostsUrl}${id}&per_page=50`)
        .then((response) => {
            setPostsTotalpages(response.headers.get('x-wp-totalpages'));
            return response.json();
        })
        .then((responseData) => {
            setPosts(responseData);
            setIsLoading(false);
        })
        .catch((error)=>{
            //fetch自体が失敗したとき
            console.log("取得に失敗しました。" + "error: " + error);
        })
        
    }, [setPosts, id])
    
    return(
        <main className="main">
            
            <Helmet>
                <title>タグ | CodeCode</title>
            </Helmet>
            
            { isLoading ? <Loading /> : <ArticleList posts={ posts } postsTotalpages={ postsTotalpages } searchPost={ searchPost } /> }
            
        </main>
    )
}

export default TagsArchives;
