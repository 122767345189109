// 記事一覧のURL
export const apiUrl = "https://cms.codecodeweb.com/wp-json/wp/v2/posts/";

// カテゴリー一覧のURL
export const apiCategoriesUrl = "https://cms.codecodeweb.com/wp-json/wp/v2/categories/";
// カテゴリー記事一覧のURL
export const apiCategoriesPostsUrl = "https://cms.codecodeweb.com/wp-json/wp/v2/posts?categories=";

// タグ一覧のURL
export const apiTagsUrl = "https://cms.codecodeweb.com/wp-json/wp/v2/tags/";
// タグ記事一覧のURL
export const apiTagsPostsUrl = "https://cms.codecodeweb.com/wp-json/wp/v2/posts?tags=";

// ページネーションのURL
export const apiPagePostsUrl = "https://cms.codecodeweb.com/wp-json/wp/v2/posts?_embed&page=";

// 検索のURL
export const apiSearchUrl = "https://cms.codecodeweb.com/wp-json/wp/v2/search?search=";
