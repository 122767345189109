import { useEffect, useMemo } from 'react';
import { Link } from "react-router-dom";
import { useRecoilState, useRecoilValue } from 'recoil';

// Atoms
import { postsCategoriesAtom, postsTagsAtom, isLoadingAtom } from '../recoil/atoms/postsatom';

// Vars
import { apiCategoriesUrl, apiTagsUrl } from '../components/vars';

const Footer = () => {
    
    const [postsCategories, setPostsCategories] = useRecoilState(postsCategoriesAtom);
    const [postsTags, setPostsTags] = useRecoilState(postsTagsAtom);
    
    useEffect(() => {
        
        fetch(apiCategoriesUrl)
        .then((response) => {
            return response.json();
        })
        .then((responseData) => {
            setPostsCategories(responseData);
        })
        .catch((error)=>{
            //fetch自体が失敗したとき
            console.log("取得に失敗しました。" + "error: " + error);
        })
        
        fetch(apiTagsUrl)
        .then((response) => {
            return response.json();
        })
        .then((responseData) => {
            setPostsTags(responseData);
        })
        .catch((error)=>{
            //fetch自体が失敗したとき
            console.log("取得に失敗しました。" + "error: " + error);
        })

        
    }, [setPostsCategories, setPostsTags])
    
    const memoCat = useMemo(() => {
        return(
            postsCategories.map((item, index) => {
                return(
                    <li key={index}><Link to={`/categories/${item.id}`}>{item.name}</Link></li>
                )
            })
        )
    })
    
    const memoTag = useMemo(() => {
        return(
            postsTags.map((item, index) => {
                return(
                    <li key={index}><Link to={`/tags/${item.id}`}>{item.name}</Link></li>
                )
            })
        )
    })
    
    return(
        <footer className="footer">
            <div className="footer_inner">
                
                <div className="footer_head">
                    <div className="footer_cats">
                        <h2><span>categories</span></h2>
                        <ul>
                            { memoCat }
                        </ul>
                    </div>
                    <div className="footer_tags">
                        <h2><span>Tags</span></h2>
                        <ul>
                            { memoTag }
                        </ul>
                    </div>
                </div>
                
                <p className="copy"><small>© CodeCode.</small></p>
            </div>
        </footer>
    )
}

export default Footer;
