import { BrowserRouter as Router, Routes, Route, Link, useRouterHistory } from "react-router-dom";

// Components
import Header from './components/header';
import Footer from './components/footer';

// Pages
import Archive from './pages/archive';
import PageArchive from './pages/pagearchive';
import CategoriesArchive from './pages/categoriesarchive';
import TagsArchive from './pages/tagsarchive';
import Single from './pages/single';
import NotFound from './pages/notfound';

const PageRouter = () => {
    
    return (
        <Router>
        
            <div className="wrap">
                
                <Header />
                
                <Routes>
                    
                    <Route path='/' element={<Archive />} />
                    <Route path='/page/:pagenumber' element={<PageArchive />} />
                    <Route path='/categories/:id' element={<CategoriesArchive />} />
                    <Route path='/tags/:id' element={<TagsArchive />} />
                    <Route path='/:id' element={<Single />} />
                    <Route element={<NotFound />} />
                    
                </Routes>
                
                <Footer />
                
            </div>
            
        </Router>
    )

}

export default PageRouter;
