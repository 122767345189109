import { Link } from "react-router-dom";

const Header = () => {
    return(
        <header className="header">
            <div className="header_inner">
                <h1><Link to="/">CodeCode</Link></h1>
                <p>なんか色々おぼえ書き。だいたいweb制作関連。</p>
            </div>
        </header>
    )
}

export default Header;
