import { createRoot } from 'react-dom/client';
import { RecoilRoot } from 'recoil';

// Components
import PageRouter from './pagerouter';

const App = () => {
    
    return (
        <RecoilRoot>
            <PageRouter />
        </RecoilRoot>
    )

}

//レンダリング
const container = document.getElementById('app');
const root = createRoot(container);
root.render(<App />);
