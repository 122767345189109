// Components
import Pagination from './pagination';
import ArticleListItem from './articleListItem';
import NoResults from './noresults';

const ArticleList = props => {
    
    return(
        <article className="articles">
            
            { props.posts.length >= 1 || props.searchPost.length >= 1 ? <ArticleListItem posts={ props.posts } searchPost={ props.searchPost } /> : <NoResults /> }
            
            <Pagination postsTotalpages={ props.postsTotalpages } />
            
        </article>
    )
}

export default ArticleList;
