import { useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from 'recoil';
import { Helmet } from "react-helmet";

// Components
import Loading from '../components/loading';
import NotFound from '../pages/notfound';

// Atoms
import { singleAtom, isLoadingAtom } from '../recoil/atoms/postsatom';

// Vars
import { apiUrl } from '../components/vars';

const Single = () => {
    
    const { id } = useParams();
    
    const [isLoading, setIsLoading] = useRecoilState(isLoadingAtom);
    const [single, setSingle] = useRecoilState(singleAtom);
    
    useEffect(() => {
        
        setIsLoading(true);
        
        fetch(`${apiUrl}${id}?_embed&categories&tags`)
        .then((response) => {
            return response.json();
        })
        .then((responseData) => {
            setSingle(responseData);
            setIsLoading(false);
        })
        .catch((error)=>{
            //fetch自体が失敗したとき
            console.log("取得に失敗しました。" + "error: " + error);
        })

        
    }, [setSingle])
    
    const datetime = (dateStr) => {
        const date = new Date(dateStr);
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        const formattedDate = `${year}.${month}.${day}`;
        return formattedDate;
    }
    
    const tagReplace = (text) => {
        if(text){
            return text.replace(/(<([^>]+)>)/gi, '');
        }
    }
    
    if(single.data?.status){
        return <NotFound />
    }else{
        
        if(isLoading){
        
            return(
                <main className="main">
                    <Loading />
                </main>
            )
            
        }else{
            
            return(
                <main className="main">
                    
                    <Helmet>
                        <title>{ `${single.title?.rendered} | CodeCode` }</title>
                        <meta name="description" content={ tagReplace(single.excerpt?.rendered) } />
                        
                        <meta property="og:url" content={ `https://codecodeweb.com/${single.id}` } />
                        <meta property="og:title" content={ single.title?.rendered } />
                        <meta property="og:description" content={ tagReplace(single.excerpt?.rendered) } />
                        <meta name="twitter:url" content={ `https://codecodeweb.com/${single.id}` } />
                        <meta name="twitter:title" content={ single.title?.rendered } />
                        <meta name="twitter:description" content={ tagReplace(single.excerpt?.rendered) } />
                        
                    </Helmet>
                            
                    <article className="single">
                        <div className="single_head">
                            <h2>{ single.title?.rendered }</h2>
                            
                            <p className="datetime"><time dateTime={single.date}>{datetime(single.date)}</time></p>
                            <div className="categories">
                                <ul>
                                    {
                                        single._embedded != undefined ? 
                                            // single._embedded["wp:term"][0]がカテゴリー
                                            single._embedded["wp:term"][0].map((item, index) => {
                                                return <li key={index}><Link to={`/categories/${item.id}`}>{item.name}</Link></li>
                                            })
                                        :
                                        ""
                                    }
                                </ul>
                            </div>
                            <div className="tags">
                                <ul>
                                {
                                    single._embedded != undefined ? 
                                        // single._embedded["wp:term"][1]がタグ
                                        single._embedded["wp:term"][1].map((item, index) => {
                                            return <li key={index}><Link to={`/tags/${item.id}`}>{item.name}</Link></li>
                                        })
                                    :
                                    ""
                                }
                                </ul>
                            </div>
                        </div>
                        
                        <div className="single_main" dangerouslySetInnerHTML={{ __html: single.content ? single.content.rendered : "" }}></div>
                        
                    </article>
                    
                    <div className="button_return">
                        <Link to="/">TOPへ戻る</Link>
                    </div>
                    
                </main>
            )
            
        }
        
    }
    
}

export default Single;
