const NoResults = () => {
    
    return(
        <div className="notfound">
            <h2>No Results</h2>
            <p>検索結果はありませんでした。</p>
        </div>
    )
}

export default NoResults;
