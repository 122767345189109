import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const NotFound = () => {
    return(
        <main className="main">
            
            <Helmet>
                <title>404 NotFound | CodeCode</title>
            </Helmet>
                    
            <div className="notfound">
                <h2>404 NotFound</h2>
                <p>ページが見つかりませんでした。</p>
                
                <div className="button_return">
                    <Link to="/">TOPへ戻る</Link>
                </div>
            </div>
            
        </main>
    )
}

export default NotFound;
