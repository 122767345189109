import { useRecoilValue } from 'recoil';

// Atoms
import { searchQueryAtom } from '../recoil/atoms/postsatom';

const Search = props => {
    
    const searchQuery = useRecoilValue(searchQueryAtom)
    
    return(
        <div className="search">
            <input type="text" value={searchQuery} placeholder="検索" onChange={props.onChangeInput}/>
        </div>
    )
}

export default Search;
