const ArticleListItem = props => {
    
    const datetime = (dateStr) => {
        const date = new Date(dateStr);
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        const formattedDate = `${year}.${month}.${day}`;
        return formattedDate;
    }
    
    if(props.posts.length >= 1 && props.searchPost.length == 0){
        
        return(
            <ul className="articles_list">
                
                {
                    props.posts.map((post, index) => {
                        return(
                            <li key={index}>
                                <a href={`/${post.id}`}>
                                    <time>{datetime(post.date)}</time>
                                    <h2>{post.title.rendered}</h2>
                                </a>
                            </li>
                        )
                    })
                }
                    
            </ul>
        )
        
    }else{
        
        return(
            <ul className="articles_list">
                
                {
                    props.searchPost.map((post, index) => {
                        return(
                            <li key={index}>
                                <a href={`/${post.id}`}>
                                    <time dateTime={post._embedded?.self[0].date}>{datetime(post._embedded?.self[0].date)}</time>
                                    <h2>{post.title}</h2>
                                </a>
                            </li>
                        )
                    })
                }
                    
            </ul>
        )
        
    }
}

export default ArticleListItem;
