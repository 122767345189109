import { atom } from 'recoil';

// ローディングの判定
export const isLoadingAtom = atom({
    key: 'isLoadingAtom',
    default: false,
});





// 個別記事のデータ
export const singleAtom = atom({
    key: 'singleAtom',
    default: [],
});

// 総記事数
export const postsTotalpagesAtom = atom({
    key: 'postsTotalpagesAtom',
    default: [],
});

// 取得した記事一覧
export const postsAtom = atom({
    key: 'postsAtom',
    default: [],
});


// カテゴリー一覧
export const postsCategoriesAtom = atom({
    key: 'postsCategoriesAtom',
    default: [],
});





// タグ一覧
export const postsTagsAtom = atom({
    key: 'postsTagsAtom',
    default: [],
});



// ページネーションの記事一覧のエンドポイント
export const currentPageAtom = atom({
    key: 'currentPageAtom',
    default: 1,
});







// 検索結果の記事一覧
export const postsSearchAtom = atom({
    key: 'postsSearchAtom',
    default: [],
});

// 検索クエリ
export const searchQueryAtom = atom({
    key: 'searchQueryAtom',
    default: "",
});

// 検索結果の記事一覧
export const searchPostAtom = atom({
    key: 'searchPostAtom',
    default: [],
});
