import { Link } from "react-router-dom";

import { useRecoilValue } from 'recoil';

// Atoms
import { currentPageAtom } from '../recoil/atoms/postsatom';

const Pagination = props => {
    
    const currentPage = useRecoilValue(currentPageAtom);
    
    if(props.postsTotalpages > 1){
        
        return(
            <div className="pagination" id="pagination">
                <ul>
                    {
                        [...Array(Number(props.postsTotalpages))].map((number, index) => (
                            
                            currentPage == index + 1 ? <li key={index}><a>{index + 1}</a></li> : <li key={index}><Link to={`/page/${index + 1}`}>{index + 1}</Link></li>
                            
                        ))
                    }
                </ul>
            </div>
        )
        
    }else{
        return null
    }
}

export default Pagination;
